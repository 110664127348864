export const CONNECTION_GAMES = [
  [
    {
      category: "Black Women Authors",
      words: ["Toni", "Paule", "Zora", "Alice"],
      difficulty: 1,
      imageSrc: "https://i.ibb.co/Gn36Ypb/Connections-1.png",
    },
    {
      category: "Michael ____",
      words: ["Jackson", "Jordan", "Johnson", "Tyson"],
      difficulty: 2,
      imageSrc: "https://i.ibb.co/3B44zXX/Connections-2.png",
    },
    {
      category: "Black Greek Sorority Symbols",
      words: ["Poodle", "Dove", "Ivy", "Pyramid"],
      difficulty: 3,
      imageSrc: "https://i.ibb.co/4MwD7zv/Connections-3.png",
    },

    {
      category: "Boyz II Men",
      words: ["Michael", "Nathan", "Wanyá", "Shawn"],
      difficulty: 4,
      imageSrc:
        "https://i.ibb.co/V2vnr5P/boys2men-2-d53afdddf480401fac1204a3954aa5f6.webp",
    },
  ],
  [
    {
      category: "Countries in West Africa",
      words: ["Nigeria", "Cameroon", "Togo", "Benin"],
      difficulty: 1,
    },
    {
      category: "Countries in East Africa",
      words: ["Tanzania", "Kenya", "Ethiopia", "Somalia"],
      difficulty: 2,
    },
    {
      category: "Countries in North Africa",
      words: ["Morocco", "Algeria", "Tunisia", "Libya"],
      difficulty: 3,
    },
    {
      category: "African Islands",
      words: ["Madagascar", "Mauritius", "Comoros", "Mayotte"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "California Rappers",
      words: ["O'Shea", "Eric", "Lesane", "Todd"],
      difficulty: 1,
    },
    {
      category: "Black Founded Clothing Brands",
      words: ["Fubu", "Phat Farm", "Enyce", "Rocawear"],
      difficulty: 2,
    },
    {
      category: "Hair Cut Styles",
      words: ["Ceasar", "Wavelength", "Fade", "Taper"],
      difficulty: 3,
    },
    {
      category: "Black Mayors",
      words: ["Bass", "Turner", "Adams", "Washington"],
      difficulty: 4,
      imageSrc: "https://i.ibb.co/pP1xn0Z/Connections.png",
    },
  ],
  [
    {
      category: "Church Leadership Roles",
      words: ["Deacon", "Elder", "Minister", "Pastor"],
      difficulty: 1,
      imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/Church-Roles.png",
    },
    {
      category: "Synonyms for Lucky",
      words: ["Blessed", "Fortunate", "Favored", "Providential"],
      difficulty: 2,
      imageSrc:
        "https://d1isxmzxwhhjbk.cloudfront.net/3/Synonyms-for-Lucky.png",
    },
    {
      category: "R&B Icons",
      words: ["Tyrese", "Usher", "Brandy", "Maxwell"],
      difficulty: 3,
      imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/R-B-Icons.png",
    },

    {
      category: "Tupac's Movie Character Names",
      words: ["Bishop", "Lucky", "Birdie", "Tank"],
      difficulty: 4,
      imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/Tupac-Movie-Roles.png",
    },
  ],
  [
    {
      category: "Slang for 'A Lot'",
      words: ["Bare", "Mad", "Hella", "Buku"],
      difficulty: 1,
    },
    {
      category: "Slang for 'Great'",
      words: ["Cold", "Raw", "Dope", "Lit"],
      difficulty: 2,
    },
    {
      category: "Ways to Refer to Money",
      words: ["Brick", "Band", "Rack", "C-Note"],
      difficulty: 3,
    },

    {
      category: "Things Needed to Make a Pizza",
      words: ["Cheddar", "Dough", "Sauce", "Heat"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Boondocks Characters",
      words: ["Riley", "Huey", "Jazmine", "Robert"],
      difficulty: 1,
      imageSrc:
        "https://d1isxmzxwhhjbk.cloudfront.net/4/Boondocks-Characters.png",
    },
    {
      category: "Uncle ____",
      words: ["Ruckus", "Tom", "Luke", "Ben's"],
      difficulty: 2,
      imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Uncle___.png",
    },
    {
      category: "Bobby ____",
      words: ["Brown", "Womack", "Schmurda", "Valentino"],
      difficulty: 3,
      imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Bobby___.png",
    },
    {
      category: "Black Panther Leadership",
      words: ["Bobby", "Fred", "Elaine", "Kathleen"],
      difficulty: 4,

      imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Black-Panthers.png",
    },
  ],
  [
    {
      category: "To Buy",
      words: ["Cop", "Purchase", "Acquire", "Procure"],
      difficulty: 1,
    },
    {
      category: "Slang for Jewelry",
      words: ["Rock", "Bling", "Ice", "Stud"],
      difficulty: 2,
    },
    {
      category: "Farm Animals",
      words: ["Horse", "Pig", "Cow", "Chicken"],
      difficulty: 3,
    },
    {
      category: "Things A Joint May Refer To",
      words: ["Blunt", "Woman", "Place", "Elbow"],
      difficulty: 4,
    },
  ],
];
